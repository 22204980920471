
.App {
  text-align: center;
}

.App-header {
  background-size: cover;
  background-position: center;
  min-height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

input[type="file"] {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 1rem;
  padding: 1rem;
}

/* Default (large screens) */
.App-header {
  background-size: cover;
  background-position: center;
  height: 150px; 
  background-image: url('Assets/AP.png'); 
}

/* Small screens */
@media (max-width: 768px) {
  .App-header {
    background-image: url('Assets/AP1.png');
    height: 100px; 
    width: 100%; 
    padding-top: 0px;
  }
}

/* Extra small screens */
@media (max-width: 480px) {
  .App-header {
    background-image: url('Assets/AP1.png'); /* Add your extra small banner image URL here */
    height: auto; 
    width: 100%; 
    background-size: contain; 
    background-repeat: no-repeat; 
    margin-top: -50px;
    margin-bottom: -60px;
    margin-left: 0;
    margin-right: 0;
  }
}